import { isNil } from "lodash";
import router from "@/router";
import axios from "axios";
import { sha256 } from "js-sha256";

interface Callback {
  (message: any): void;
}

export default {
  isAuthenticated() {
    const token = localStorage.getItem("token");

    return !isNil(token);
  },

  get_user_details() {
    const apiUserDetailsPath =
      process.env.VUE_APP_READERBENCH_API_USER_DETAILS_ENDPOINT;

    if (!apiUserDetailsPath) {
      throw new Error(
        "API User details path is not defined in environment variables"
      );
    }

    return axios.get(apiUserDetailsPath);
  },

  get_access_token(
    creds,
    redirect: string,
    onSuccess: Callback,
    onError: Callback
  ) {
    const code_verifier = localStorage.getItem("code_verifier");
    localStorage.removeItem("code_verifier");
    const payload = {
      grant_type: "authorization_code",
      client_id: process.env.VUE_APP_CLIENT_ID,
      code: creds.code,
      redirect_uri: creds.redirect_uri,
      code_verifier: code_verifier
    };

    const apiTokenPath = process.env.VUE_APP_READERBENCH_API_TOKEN_ENDPOINT;

    if (!apiTokenPath) {
      throw new Error("API Token path is not defined in environment variables");
    }

    axios
      .post(apiTokenPath, payload)
      .then((response) => {
        if (redirect) {
          router.push({ path: redirect, hash: "#logged_in" });
        }
        localStorage.setItem("token", response.data.access_token);
        localStorage.setItem("refresh_token", response.data.refresh_token);
        localStorage.setItem(
          "token_valid_until_timestamp",
          String(response.data.expires_in + Math.floor(Date.now() / 1000))
        );

        try {
          const call_user_details = this.get_user_details()

          call_user_details.then((response) => {
            onSuccess(response.data.username);
          })
          .catch((error) => {
            onError(error);
          });
        } catch (error) {
          onError({response: {data: {error_description: error}}})
        }
      })
      .catch((error) => {
        onError(error);
      });
  },

  refresh_access_token(onSuccess: Callback, onError: Callback) {
    const payload = {
      grant_type: "refresh_token",
      client_id: process.env.VUE_APP_CLIENT_ID,
      refresh_token: localStorage.getItem("refresh_token"),
    };

    const apiTokenPath = process.env.VUE_APP_READERBENCH_API_TOKEN_ENDPOINT;

    if (!apiTokenPath) {
      throw new Error("API Token path is not defined in environment variables");
    }

    axios
      .post(apiTokenPath, payload)
      .then((response) => {
        localStorage.setItem("token", response.data.access_token);
        if (response.data.refresh_token != undefined)
          localStorage.setItem("refresh_token", response.data.refresh_token);
        localStorage.setItem(
          "token_valid_until_timestamp",
          String(response.data.expires_in + Math.floor(Date.now() / 1000))
        );

        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  },

  login(window) {
    // Generate code verifier
    const code_verifier = Array.from({ length: 128 }, () =>
      String.fromCharCode(Math.floor(Math.random() * 95) + 32)
    ).join("");
    localStorage.setItem("code_verifier", code_verifier);

    // string of 64 hex characters -> 32 bytes -> 32 ascii characters
    const code_verifier_hashed = sha256(code_verifier); 

    // Because the code verifier is a string of 64 hex characters, 
    // we need to convert it to a string of the corresponding ascii characters
    // because btoa expects a string of ascii characters. Otherwise it will treat a hex character as an ascii character.
    let code_verifier_hashed_ascii = "";
    for (let i = 0; i < code_verifier_hashed.length; i += 2) {
      const ascii_char = String.fromCharCode(parseInt(code_verifier_hashed.substring(i, i + 2), 16));
      code_verifier_hashed_ascii += ascii_char;
    }
    const code_verifier_hashed_base64 = btoa(code_verifier_hashed_ascii);

    // what this does is:
    // 1. remove the padding characters '='
    // 2. replace '+' with '-'
    // 3. replace '/' with '_'
    // this is because the base64url encoding is a variant of base64 encoding that uses URL and filename safe alphabet
    const code_challenge = code_verifier_hashed_base64.replace(/=/g, "").replace(/\+/g, "-").replace(/\//g, "_");

    // Redirect to the login page with the code challenge and the method used to generate it
    window.location.href = process.env.VUE_APP_READERBENCH_API_LOGIN_URL + `&code_challenge=${code_challenge}` + `&code_challenge_method=S256`;
  },

  logout: function (callback?: Callback) {
    const apiLogoutUrl = process.env.VUE_APP_READERBENCH_API_LOGOUT_URL;

    if (!apiLogoutUrl) {
      throw new Error(
        "API Logout path is not defined in environment variables"
      );
    }

    axios.get(apiLogoutUrl, { withCredentials: true }).then(() => {
      this.clearLocalStorage();
      router.push({ path: "/", hash: "#logged_out" });

      if (callback) {
        callback("You have been logged out");
      }
    });
  },

  clearLocalStorage() {
    localStorage.removeItem("token");
    localStorage.removeItem("token_valid_until_timestamp");
    localStorage.removeItem("refresh_token");
  },
};
